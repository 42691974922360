import React from 'react';
import confirm from '../../../../shared/components/confirm.js'
import confirmText from '../../../../shared/components/confirm_text.js'
import AsyncButton from '../../../../shared/components/async_button';
import DomUtil from '../../../../shared/util/dom_util';
import Converter from '../../../../shared/util/converter';
import Mapper from '../../../../shared/util/mapper'

class ConfirmArrival extends React.Component {

  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);

    this.state = {
      flow_patch: {
        status: "confirm_arrival",
        action: "arrival_confirmed"
      }
    };
  }
  
  updateTransaction(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure you want to acknowledge the proof of delivery?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      this.props.updateTransaction(this.state.flow_patch).then(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      }).catch(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }

  objectArrival(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirmText("Please confirm", "Are you sure you want to object to the proof of delivery? Please state the reason.", { multiline: true }).then((result) => {
      DomUtil.disableClass(button);
      this.props.updateTransaction( {
        status: "confirm_arrival",
        action: "arrival_objected",
        reason: result
      }).then(() => {
        DomUtil.enableClass(button);
      }).catch(() => {
        DomUtil.enableClass(button);
      });
    });
  }
  
  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Acknowledgement proof of delivery</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Please review and acknowledge the proof of delivery. If the proof of delivery is incorrect, please make an objection and the transaction will be put on hold.</span>
              <br/>
              <span>You have until {Converter.toDate(this.props.transaction.final_objection_date)} 23:59 to acknowledge or object the proof of delivery, after this date it is assumed that the proof of delivery is acknowledged.</span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">

              <form autoComplete="off" onSubmit={this.updateTransaction.bind(this)}>
                
                <div className="row">
                  <div className="col-md">
                    <AsyncButton
                      type="submit"
                      variant="outlined"
                      color="primary"
                      icon="check"
                      text="Acknowledge" />
                    <AsyncButton
                      className="button-danger-outlined"
                      variant="outlined"
                      color="danger"
                      onClick={this.objectArrival.bind(this)}
                      icon="error_outline"
                      text="Object" />
                  </div>
                </div>

              </form>

            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ConfirmArrival;
