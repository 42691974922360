import React from 'react';
import Alert from '@material-ui/lab/Alert';
import confirm from '../../../../shared/components/confirm.js'
import AsyncButton from '../../../../shared/components/async_button.js';
import DomUtil from '../../../../shared/util/dom_util.js';

class OnHold extends React.Component {

  constructor(props) {
    super(props)
    this.updateTransaction = this.updateTransaction.bind(this);

    this.state = {
      flow_patch: {
        status: "on_hold",
        action: "resolved"
      }
    };
  }
  
  updateTransaction(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure the dispute is resolved and want to continue the transaction?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      this.props.updateTransaction(this.state.flow_patch).then(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      }).catch(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }
  
  render() {
    return (
      <div>

        { this.props.transaction.messages && this.props.transaction.messages.length > 0 &&
          <div className="row action-required-row">
            <div className="col-md-12">
              <Alert style={{ whiteSpace: "pre-line" }} severity="warning">The proof of delivery was objected by {this.props.transaction.messages[0].message_by.registered_name}. Reason: {this.props.transaction.messages[0].message}</Alert>
            </div>
          </div>
        }
          
        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>On hold</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Buyer objected the proof of delivery, transaction is put on hold.</span>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Please resolve the dispute and continue the transaction.</span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">

              <form autoComplete="off" onSubmit={this.updateTransaction.bind(this)}>
                
                <div className="row">
                  <div className="col-md">
                    <AsyncButton
                      type="submit"
                      variant="outlined"
                      color="primary"
                      icon="check"
                      text="Resolved" />
                  </div>
                </div>

              </form>

            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default OnHold;
