import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Mapper from '../../../../shared/util/mapper';
import DocumentTypeField from '../../../../shared/components/document_type_field'

class TransportMilestonesForm extends React.Component {

  constructor(props) {
    super(props)

    var transport_milestones = [
      { type: "at_departure", required_documents: [ { key: this.getUniqueKey(), type: "packing_list"} ] },
      { type: "at_ship_loading", required_documents: [ { key: this.getUniqueKey(), type: "bill_of_lading"} ] },
      { type: "at_arrival", required_documents: [ { key: this.getUniqueKey(), type: "proof_of_delivery"} ] }
    ];

    if(this.props.transaction.transport_milestones && this.props.transaction.transport_milestones.length > 0) {
      for(const transport_milestone of this.props.transaction.transport_milestones) {
        var existing_milestone = transport_milestones.find(m => m.type === transport_milestone.type);
        var that = this;
        transport_milestone.required_documents.forEach(function (required_document) {
          required_document.key = that.getUniqueKey();
        });      
        existing_milestone.required_documents = transport_milestone.required_documents;
      }
    }
    
    this.state = {
      transport_milestones: transport_milestones
    };

    var that = this;
    Object.keys(this.state).map(function (key) {
      that.props.propertyUpdate(key, that.state[key]);
    });
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  addRequiredDocument(index, e) {
    this.state.transport_milestones[index].required_documents.push({ key: this.getUniqueKey() });
    this.setState({ transport_milestones: this.state.transport_milestones });  
    this.props.propertyUpdate("transport_milestones", this.state.transport_milestones);
  }

  removeRequiredDocument(index, document_index, e) {
    this.state.transport_milestones[index].required_documents.splice(document_index, 1);
    this.setState({ transport_milestones: this.state.transport_milestones });  
    this.props.propertyUpdate("transport_milestones", this.state.transport_milestones);
  }
  
  requiredDocumentTypeUpdated(required_document, parent_data) {
    this.state.transport_milestones[parent_data.index].required_documents[parent_data.document_index] = required_document;
    this.setState({ transport_milestones: this.state.transport_milestones });  
    this.props.propertyUpdate("transport_milestones", this.state.transport_milestones);
  }

  render() {
    return (
      <div>
      
      <div className="row">
        <div className="col-md">
          <span>Documents that are required for upload at the specific transport miltestones can be specified.&nbsp;</span>
          <span>The Sea Waybill and Proof of delivery are required.</span>
        </div>
      </div>

      {this.state.transport_milestones.map((transport_milestone, index) => {
        return (
          <div key={transport_milestone.type} className="list-row-item">
            <span className="list-row-item-header-text">{Mapper.fromTransportMilestoneTypes(transport_milestone.type)}</span>

            {transport_milestone.required_documents.map((required_document, document_index) => {
              var options = Mapper.allowedDocumentTypes();
              if(required_document.type && !options[required_document.type]) {
                var disabled = true;
                options = Mapper.allDocumentTypes();
              }

              return (
                <div key={required_document.key} className="row">
                  <div className="col-md-4">
                      <DocumentTypeField
                        disabled={disabled}
                        requiredDocumentTypeUpdated={this.requiredDocumentTypeUpdated.bind(this)}
                        required_document={required_document}
                        parent_data={{ index: index, document_index: document_index }}
                      />
                    {!disabled &&
                      <Button
                        className="button-icon-only button-inline end-off-input-button"
                        color="secondary"
                        type="button"
                        onClick={this.removeRequiredDocument.bind(this, index, document_index)}
                        startIcon={<Icon>clear</Icon>} />
                    }
                  </div>
                </div>
              );
            })}

            <div className="row">
              <div className="col-md">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.addRequiredDocument.bind(this, index)}
                  startIcon={<Icon>add</Icon>}>
                  Add required document
                </Button>
              </div>
            </div>
          </div>
        );
      })}
        
      </div>
    );
  }
}

export default TransportMilestonesForm;
