import React from 'react';
import Alert from '@material-ui/lab/Alert';

class OnHold extends React.Component {

  render() {
    return (
      <div>

        { this.props.transaction.messages && this.props.transaction.messages.length > 0 &&
          <div className="row action-required-row">
            <div className="col-md-12">
              <Alert style={{ whiteSpace: "pre-line" }} severity="warning">The proof of delivery was objected by {this.props.transaction.messages[0].message_by.registered_name}. Reason: {this.props.transaction.messages[0].message}</Alert>
            </div>
          </div>
        }

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>On hold</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Buyer objected the proof of delivery, transaction is put on hold.</span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default OnHold;
