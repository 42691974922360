import React from 'react';
import Converter from '../../../util/converter';

class ConfirmArrival extends React.Component {

  render() {
    return (
      <div>

        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Acknowledgement proof of delivery</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Waiting for Buyer to review and acknowledge the proof of delivery.</span>
              <br/>
              <span>The Buyer has until {Converter.toDate(this.props.transaction.final_objection_date)} 23:59 to acknowledge or object the proof of delivery, after this date it is assumed that the proof of delivery is acknowledged.</span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ConfirmArrival;
