import React from 'react';

class Mapper extends React.Component {

  static fromFlowParentStatus(key) {
    var lookup = this.flowParentStatuses()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static flowParentStatuses() {
    return {
      "details": "Details",
      "compliance": "Compliance",
      "agreement": "Agreement",
      "payment": "Payment",
      "departure": "Departure",
      "in_transit": "In transit",
      "loading": "Loading",
      "release": "Release",
      "completed": "Completed",
      "rejected": "Rejected",
      "deleted": "Deleted"
    };
  }

  static toFlowParentStatusBadge(key) {
    return {
      "details": "badge-active",
      "compliance": "badge-active",
      "agreement": "badge-active",
      "payment": "badge-active",
      "departure": "badge-active",
      "in_transit": "badge-active",
      "loading": "badge-active",
      "release": "badge-active",
      "completed": "badge-completed",
      "rejected": "badge-error",
      "deleted": "badge-error"
    }[key];
  }

  static fromMainTransportMode(key) {
    var lookup = this.mainTransportModes()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static mainTransportModes() {
    return {
      "sea": "Sea"
    };
  }

  static fromTransactionRole(key) {
    var lookup = this.transactionRoles()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static transactionRoles() {
    return {
      "buyer": "Buyer",
      "seller": "Seller"
    };
  }

  static fromIncoterms(key) {
    var lookup = this.incoterms()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static incoterms() {
    return {
      "cfr": "CFR – Cost and Freight",
      "cif": "CIF – Cost, Insurance & Freight",
      "cpt": "CPT – Carriage Paid To",
      "cip": "CIP – Carriage and Insurance Paid To",
      "dpu": "DPU – Delivered at Place Unloaded",
      "dap": "DAP – Delivered at Place",
      "ddp": "DDP – Delivered Duty Paid"
    };
  }

  static fromIncotermsShort(key) {
    var lookup = this.incotermsShort()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static incotermsShort() {
    return {
      "cfr": "CFR",
      "cif": "CIF",
      "cpt": "CPT",
      "cip": "CIP",
      "dpu": "DPU",
      "dap": "DAP",
      "ddp": "DDP"
    };
  }

  static fromCurrency(key) {
    var lookup = this.currencies()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static currencies() {
    return {
      "eur": "Euro",
      "usd": "US Dollar"
    };
  }

  static fromCurrencySymbol(key) {
    var lookup = this.currencySymbols()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static currencySymbols() {
    return {
      "eur": "€",
      "usd": "US$"
    };
  }

  static fromPaymentStatus(key) {
    var lookup = this.paymentStatuses()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static paymentStatuses() {
    return {
      "outstanding": "Outstanding",
      "in_escrow": "In escrow",
      "partial_funds_released": "Partial funds released",
      "funds_released": "Funds released"
    };
  }

  static toPaymentStatusBadge(key) {
    return {
      "outstanding": "badge-default",
      "in_escrow": "badge-active",
      "partial_funds_released": "badge-active",
      "funds_released": "badge-completed"
    }[key];
  }

  static fromTransportStatus(key) {
    var lookup = this.transportStatuses()[key];
    if(!lookup) {
      return "Pending";
    }
    return lookup;
  }

  static transportStatuses() {
    return {
      "pending": "Shipment pending",
      "in_transit": "In transit",
      "arrived": "Arrived",
      "released": "Released"
    };
  }

  static toTransportStatusBadge(key) {
    return {
      "pending": "badge-default",
      "in_transit": "badge-active",
      "arrived": "badge-completed",
      "released": "badge-completed"
    }[key];
  }

  static fromDocumentType(key) {
    var lookup = this.allDocumentTypes()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static allowedDocumentTypes() {
    return {
      "certificate_of_origin": "Certificate of origin",
      "inspection_report": "Pre-loading survey report",
      "dangerous_goods_declaration": "Dangerous goods declaration",
      "technical_specs": "Technical specs",
      "safety_data_sheet": "Safety data sheet",
      "cmr": "CMR",
      "cargo_picture": "Cargo pictures",
      "other": "Other"
    };
  }

  static allDocumentTypes() {
    return {
      "packing_list": "Packing list",
      "bill_of_lading": "Sea Waybill",
      "proof_of_delivery": "Proof of delivery",
      ...this.allowedDocumentTypes()
    };
  }

  static fromTransportMilestoneTypes(key) {
    var lookup = this.transportMilestoneTypes()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static transportMilestoneTypes() {
    return {
      "at_departure": "At departure",
      "at_ship_loading": "At ship loading",
      "at_arrival": "At arrival"
    };
  }

  static fromTrackingDocumentType(key) {
    var lookup = this.trackingDocumentTypes()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static trackingDocumentTypes() {
    return {
      "container_number": "Container number",
      "master_bill_of_lading": "Master B/L"
    };
  }

  static fromTrackingStatus(key) {
    var lookup = this.trackingStatuses()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static trackingStatuses() {
    return {
      "in_progress": "In progress",
      "booked": "Booked",
      "loaded": "Loaded",
      "sailing": "Sailing",
      "arrived": "Arrived",
      "discharged": "Discharged",
      "untracked": "Untracked",
      "skipped": "Skipped",
      "unknown": "Unknown"
    };
  }

  static toTrackingStatusBadge(key) {
    return {
      "in_progress": "badge-default",
      "booked": "badge-active",
      "loaded": "badge-active",
      "sailing": "badge-active",
      "arrived": "badge-active",
      "discharged": "badge-completed",
      "untracked": "badge-error",
      "skipped": "badge-error",
      "unknown": "badge-default"
    }[key];
  }

  static fromTrackingMilestoneStatus(key) {
    var lookup = this.trackingMilestoneStatuses()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static trackingMilestoneStatuses() {
    return {
      "pending": "Pending",
      "completed": "Completed",
      "unknown": "Unknown"
    };
  }

  static toTrackingMilestoneStatusBadge(key) {
    return {
      "pending": "badge-active",
      "completed": "badge-completed",
      "unknown": "badge-default"
    }[key];
  }

  static fromBoolean(key) {
    var lookup = this.booleans()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static booleans() {
    return {
      "true": "Yes",
      "false": "No"
    };
  }

  static fromUserRole(key) {
    var lookup = this.userRoles()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static userRoles() {
    return {
      "read_only": "Read only",
      "default": "Default",
      "authorised": "Authorised",
      "admin": "Admin"
    };
  }

  static fromApiRole(key) {
    var lookup = this.apiRoles()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static apiRoles() {
    return {
      "read_only": "Read only",
      "default": "Default",
      "admin": "Admin"
    };
  }

  static fromWebhookEventTypes(key) {
    var lookup = this.webhookEventTypes()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static webhookEventTypes() {
    return {
      "trade_transaction": "Trade Transaction",
      "freight_transaction": "Freight Transaction",
      "compliance": "Compliance"
    };
  }

  static fromSource(key) {
    var lookup = this.sources()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static sources() {
    return {
      "funds": "Funds",
      "wealth": "Wealth"
    };
  }

  static fromLegalForm(key) {
    var lookup = this.legalForms()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static legalForms() {
    return {
      "private": "Private Company",
      "public": "Public Listed Company",
      "partnership": "Partnership",
      "limited": "Limited Liability Partnership",
      "other": "Other"
    };
  }

  static fromshareholderType(key) {
    var lookup = this.shareholderTypes()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static shareholderTypes() {
    return {
      "natural_person": "Natural person",
      "corporate_entity": "Corporate entity"
    };
  }

  static fromGender(key) {
    var lookup = this.genders()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static genders() {
    return {
      "male": "Male",
      "female": "Female",
      "other": "Other"
    };
  }
  
  static fromPropertyType(key) {
    var lookup = this.propertyTypes()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static propertyTypes() {
    return {
      "singapore_tax_percentage": "BTW Percentage",
      "dutch_tax_percentage": "BTW Percentage",
    };
  }
  
  static fromInvoiceStatus(key) {
    var lookup = this.invoiceStatuses()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static invoiceStatuses() {
    return {
      "open": "Open",
      "paid": "Paid",
      "overdue": "Overdue"
    };
  }

  static toInvoiceStatusBadge(key) {
    return {
      "open": "badge-active",
      "paid": "badge-completed",
      "overdue": "badge-error"
    }[key];
  }
  
  static fromInvoiceType(key) {
    var lookup = this.invoiceTypes()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static invoiceTypes() {
    return {
      "trade_transaction": "Trade Transaction",
      "freight_transaction": "Freight Transaction",
      "periodic_trade_transaction_settlement": "Periodic Trade Settlement",
      "periodic_partner_trade_transaction_settlement": "Periodic Partner Trade Settlement",
      "periodic_freight_transaction_settlement": "Periodic Freight Settlement",
      "periodic_partner_freight_transaction_settlement": "Periodic Partner Freight Settlement"
    };
  }

  static fromCalculationType(key) {
    var lookup = this.calculationTypes()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static calculationTypes() {
    return {
      "trade_transaction": "Trade transaction",
      "freight_transaction": "Freight transaction"
    };
  }

  static fromInvatationType(key) {
    var lookup = this.invatationTypes()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static invatationTypes() {
    return {
      "customer": "Customer",
      "partner": "Partner"
    };
  }

  static toDestination(incoterms) {
    switch(incoterms) {
      case "cfr":
      case "cif":
        return "port of destination";
      case "cpt":
      case "cip":
      case "dpu":
      case "dap":
      case "ddp":
        return "place of destination";
    }
    return "-";
  }
  
  static toContactCreation(contact) {
    return {
      "registration_number": contact.registration_number,
      "country_code": contact.country.code
    };
  }
  
  static toInviteCreation(invite) {
    return {
      "type": invite.type,
      "email": invite.email,
      "partner_percentage": invite.partner_percentage,
      "partner_territory": invite.partner_territory
    };
  }
  
  static toApiKeyCreation(api_key) {
    return {
      "name": api_key.name,
      "role": api_key.role
    };
  }
  
  
  static toWebhookCreation(webhook) {
    return {
      "callback_url": webhook.callback_url,
      "event_types": webhook.event_types
    };
  }
  
  static toCompanyDetails(company_details) {
    return {
      "type": company_details.type,
      "registered_name": company_details.registered_name,
      "registration_number": company_details.registration_number,
      "country_code": company_details.country.code,
      "registered_address": company_details.registered_address,
      "bank_account_number": company_details.bank_account_number,
      "beneficiary_name": company_details.beneficiary_name,
      "bank_name": company_details.bank_name,
      "bank_swift_code": company_details.bank_swift_code
    };
  }
  
  static toCompanyDetailsUpdate(company_details) {
    return {
      "type": company_details.type,
      "registered_address": company_details.registered_address,
      "bank_account_number": company_details.bank_account_number,
      "beneficiary_name": company_details.beneficiary_name,
      "bank_name": company_details.bank_name,
      "bank_swift_code": company_details.bank_swift_code
    };
  }
  
  static toUserUpdate(user_update) {
    return {
      "password": user_update.password,
      "terms": user_update.terms,
      "full_name": user_update.full_name,
      "notifications": user_update.notifications
    };
  }

  static toAdminUserUpdate(user_update) {
    return {
      "role": user_update.role,
      "full_name": user_update.full_name
    };
  }
  
  static toAccountCreation(account) {
    return {
      "email": account.email,
      "password": account.password,
      "partner_id": account.partner_id
    };
  }

  static toTransactionCreation(transaction) {
    return {
      "my_role": transaction.my_role,
      "counterparty_account_id": transaction.counterparty.id,
      "main_transport_mode": transaction.main_transport_mode,
      "incoterms": transaction.incoterms
    };
  }

  static toTransactionDetails(flow_patch) {
    return {
      "status": flow_patch.status,
      "action": flow_patch.action,
      "currency": flow_patch.currency,
      "commercial_invoice": this.toDocument(flow_patch.commercial_invoice),
      "commercial_invoice_amount": flow_patch.commercial_invoice_amount,
      "pick_up_date": flow_patch.pick_up_date,
      "place_of_loading": flow_patch.place_of_loading,
      "destination": flow_patch.destination,
      "cargo_description": flow_patch.cargo_description,
      "payment_milestones": this.toPaymentMilestones(flow_patch.payment_milestones),
      "transport_milestones": this.toTransportMilestones(flow_patch.transport_milestones)
    };
  }

  static toPaymentMilestones(payment_milestones) {
    var new_payment_milestones = [];
    for(const payment_milestone of payment_milestones) {
      new_payment_milestones.push({
        "rank": payment_milestone.rank,
        "condition": payment_milestone.condition,
        "amount": payment_milestone.amount,
        "required_documents": this.toCreateRequiredDocuments(payment_milestone.required_documents)
      });
    }
    return new_payment_milestones;
  }

  static toTransportMilestones(transport_milestones) {
    var new_transport_milestones = [];
    for(const transport_milestone of transport_milestones) {
      new_transport_milestones.push({
        "type": transport_milestone.type,
        "required_documents": this.toCreateRequiredDocuments(transport_milestone.required_documents)
      });
    }
    return new_transport_milestones;
  }

  static toCreateRequiredDocuments(required_documents) {
    var new_required_documents = [];
    for(const required_document of required_documents) {
      new_required_documents.push({
        "type": required_document.type,
        "other_name": required_document.other_name
      });
    }
    return new_required_documents;
  }

  static toMilestoneDocumentsUpload(flow_patch) {
    return {
      "status": flow_patch.status,
      "action": flow_patch.action,
      "required_documents": this.toUploadRequiredDocuments(flow_patch.required_documents)
    };
  }

  static toStartShipment(flow_patch) {
    return {
      "status": flow_patch.status,
      "action": flow_patch.action,
      "eta_delivery": flow_patch.eta_delivery,
      "required_documents": this.toUploadRequiredDocuments(flow_patch.required_documents)
    };
  }

  static toLoadedOnShip(flow_patch) {
    return {
      "status": flow_patch.status,
      "action": flow_patch.action,
      "required_documents": this.toUploadRequiredDocuments(flow_patch.required_documents),
      "carrier": flow_patch.carrier,
      "tracking_document_type": flow_patch.tracking_document_type,
      "tracking_number": flow_patch.tracking_number
    };
  }

  static toFreightLoadedOnShip(flow_patch) {
    return {
      "status": flow_patch.status,
      "action": flow_patch.action,
      "bill_of_lading": this.toDocument(flow_patch.bill_of_lading)
    };
  }

  static toArriveShipment(flow_patch) {
    return {
      "status": flow_patch.status,
      "action": flow_patch.action,
      "required_documents": this.toUploadRequiredDocuments(flow_patch.required_documents)
    };
  }

  static toReleaseFunds(flow_patch) {
    return {
      "status": flow_patch.status,
      "action": flow_patch.action,
      "proof_bank_transfer": this.toDocument(flow_patch.proof_bank_transfer),
      "amount": flow_patch.amount,
      "charges": flow_patch.charges
    };
  }

  static toUploadRequiredDocuments(required_documents) {
    var new_required_documents = [];
    for(const required_document of required_documents) {
      new_required_documents.push({
        "id": required_document.id,
        "type": required_document.type,
        "documents": this.toDocuments(required_document.documents)
      });
    }
    return new_required_documents;
  }

  static toDocument(document) {
    if(document) {
      return {
        id: document.id
      }
    }
  }

  static toDocuments(documents) {
    var new_documents = [];
    
    if(!documents) {
      return new_documents;
    }
    
    for(const document of documents) {
      new_documents.push(this.toDocument(document.document));
    }
    return new_documents;
  }

  static toCompliance(compliance) {
    return {
      "type": compliance.type,
      "registration_number_document": this.toDocument(compliance.registration_number_document),
      "registered_address": compliance.registered_address,
      "legal_form": compliance.legal_form,
      "venue_of_listing": compliance.venue_of_listing,
      "legal_form_specification": compliance.legal_form_specification,
      "phone_number": compliance.phone_number,
      "email_address": compliance.email_address,
      "bank_account_number": compliance.bank_account_number,
      "bank_name": compliance.bank_name,
      "bank_swift_code": compliance.bank_swift_code,
      "bank_address": compliance.bank_address,
      "beneficiary_name": compliance.beneficiary_name,
      "beneficiary_address": compliance.beneficiary_address,
      "intermediary_bank": compliance.intermediary_bank,
      "intermediary_bank_swift_code": compliance.intermediary_bank_swift_code,
      "business_activities": compliance.business_activities,
      "singapore_taxed": compliance.singapore_taxed,
      "source_of_wealth": compliance.source_of_wealth,
      "source_of_funds": compliance.source_of_funds,
      "source_of_funds_documents": this.toDocuments(compliance.source_of_funds_documents),
      "physical_presence": compliance.physical_presence,
      "big_shareholders": compliance.big_shareholders,
      "company_structure_document": this.toDocument(compliance.company_structure_document),
      "shareholders": this.toShareholders(compliance.shareholders),
      "director_key_executives": this.toDirectorsAndKeyExecutives(compliance.director_key_executives),
      "authorised_representatives": this.toAuthorisedRepresentatives(compliance.authorised_representatives),
      "sanction_us": compliance.sanction_us,
      "sanction_us_details": compliance.sanction_us_details,
      "sanction_un": compliance.sanction_un,
      "sanction_un_details": compliance.sanction_un_details,
      "additional_details": compliance.additional_details,
      "additional_documents": this.toDocuments(compliance.additional_documents)
    };
  }
  
  static toShareholders(shareholders) {
    var new_shareholders = [];
    for(const shareholder of shareholders) {
      if(shareholder.type === "natural_person") {
        new_shareholders.push({
          "type": shareholder.type,
          "full_name": shareholder.full_name,
          "passport_number": shareholder.passport_number,
          "passport": this.toDocument(shareholder.passport),
          "date_of_birth": shareholder.date_of_birth,
          "nationality": shareholder.nationality,
          "country_residency_code": shareholder.country_residency ? shareholder.country_residency.code : undefined,
          "residential_address_document": this.toDocument(shareholder.residential_address_document),
          "gender": shareholder.gender,
          "percentage_shareholdings": shareholder.percentage_shareholdings
        });
      } else if(shareholder.type === "corporate_entity") {
        new_shareholders.push({
          "type": shareholder.type,
          "registered_name": shareholder.registered_name,
          "country_of_incorporation_code": shareholder.country_of_incorporation ? shareholder.country_of_incorporation.code : undefined,
          "percentage_shareholdings": shareholder.percentage_shareholdings
        });
      }
    }
    return new_shareholders;
  }
  
  static toDirectorsAndKeyExecutives(director_key_executives) {
    var new_director_key_executives = [];
    for(const director_key_executive of director_key_executives) {
      new_director_key_executives.push({
        "full_name": director_key_executive.full_name,
        "user_account_id": director_key_executive.user_account ? director_key_executive.user_account.id : undefined,
        "passport_number": director_key_executive.passport_number,
        "passport": this.toDocument(director_key_executive.passport),
        "date_of_birth": director_key_executive.date_of_birth,
        "nationality": director_key_executive.nationality,
        "country_residency_code": director_key_executive.country_residency ? director_key_executive.country_residency.code : undefined,
        "residential_address_document": this.toDocument(director_key_executive.residential_address_document),
        "gender": director_key_executive.gender,
        "position": director_key_executive.position
      });
    }
    return new_director_key_executives;
  }
  
  static toAuthorisedRepresentatives(authorised_representatives) {
    var new_authorised_representatives = [];
    for(const authorised_representative of authorised_representatives) {
      new_authorised_representatives.push({
        "user_account_id": authorised_representative.user_account ? authorised_representative.user_account.id : undefined,
        "passport_number": authorised_representative.passport_number,
        "passport": this.toDocument(authorised_representative.passport),
        "date_of_birth": authorised_representative.date_of_birth,
        "nationality": authorised_representative.nationality,
        "country_residency_code": authorised_representative.country_residency ? authorised_representative.country_residency.code : undefined,
        "residential_address_document": this.toDocument(authorised_representative.residential_address_document),
        "gender": authorised_representative.gender,
        "position": authorised_representative.position
      });
    }
    return new_authorised_representatives;
  }
  
  static toSanctionDeclarations(sanction_declarations) {
    var new_sanction_declarations = [];
    for(const sanction_declaration of sanction_declarations) {
      new_sanction_declarations.push({
        "name_jurisdiction": sanction_declaration.name_jurisdiction,
        "type_activities": sanction_declaration.type_activities,
        "activities": sanction_declaration.activities,
        "revenue": sanction_declaration.revenue,
        "assets": sanction_declaration.assets
      });
    }
    return new_sanction_declarations;
  }
  
  static toDocuments(documents) {
    var new_documents = [];
    
    if(!documents) {
      return new_documents;
    }

    for(const document of documents) {
      new_documents.push(this.toDocument(document));
    }

    return new_documents;
  }
  
  static toDocument(document) {
    if(!document || !document.id) {
      return;
    }

    return {
      "id": document.id
    };
  }

  static fromComplianceDocumentStatus(key) {
    var lookup = this.complianceDocumentStatuses()[key];
    if(!lookup) {
      return "-";
    }
    return lookup;
  }

  static complianceDocumentStatuses() {
    return {
      "draft": "Draft",
      "submitted": "Submitted",
      "clarification": "Clarification",
      "approved": "Approved",
      "rejected": "Rejected",
      "expired": "Expired"
    };
  }

  static toComplianceDocumentStatusBadge(key) {
    return {
      "draft": "badge-default",
      "submitted": "badge-active",
      "clarification": "badge-default",
      "approved": "badge-completed",
      "rejected": "badge-error",
      "expired": "badge-error"
    }[key];
  }
  
  static toComplianceSignature(compliance_signature, agree_terms) {
    return {
      signature: compliance_signature.signature,
      agree_terms: agree_terms
    };
  }
}

export default Mapper;
