import React from 'react';

class MilestoneDocumentsReview extends React.Component {
  
  render() {
    return (
      <div>
        
        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Payment milestone document review</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <span>Waiting for Buyer to review and approve the uploaded documents for payment milestone #{this.props.transaction.current_payment_milestone.rank} ({this.props.transaction.current_payment_milestone.condition}).</span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default MilestoneDocumentsReview;
