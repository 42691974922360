"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Mercurion Trade Trade Transactions Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TradeTransactionsApi = exports.TradeTransactionsApiFactory = exports.TradeTransactionsApiFp = exports.TradeTransactionsApiAxiosParamCreator = exports.TransportStatus = exports.TransportMilestoneType = exports.TradeTransactionStatus = exports.TradeTransactionStateStatus = exports.TradeTransactionRole = exports.TradeTransactionDocumentType = exports.TradeTransactionAction = exports.TrackingStatus = exports.TrackingMilestoneStatus = exports.TrackingDocumentType = exports.PaymentStatus = exports.PaymentChargeStatus = exports.PaymentCharge = exports.MainTransportMode = exports.Incoterms = exports.Currency = exports.AccountType = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.AccountType = {
    Admin: 'admin',
    Customer: 'customer',
    Escrow: 'escrow',
    Partner: 'partner'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.Currency = {
    Eur: 'eur',
    Usd: 'usd'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.Incoterms = {
    Cfr: 'cfr',
    Cif: 'cif',
    Cpt: 'cpt',
    Cip: 'cip',
    Dpu: 'dpu',
    Dap: 'dap',
    Ddp: 'ddp'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.MainTransportMode = {
    Sea: 'sea'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.PaymentCharge = {
    EscrowFee: 'escrow_fee',
    SingaporeTax: 'singapore_tax',
    ExtensionFee: 'extension_fee'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.PaymentChargeStatus = {
    Estimation: 'estimation',
    Final: 'final'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.PaymentStatus = {
    Outstanding: 'outstanding',
    InEscrow: 'in_escrow',
    FundsReleased: 'funds_released'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.TrackingDocumentType = {
    ContainerNumber: 'container_number',
    MasterBillOfLading: 'master_bill_of_lading'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.TrackingMilestoneStatus = {
    Pending: 'pending',
    Completed: 'completed'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.TrackingStatus = {
    InProgress: 'in_progress',
    Booked: 'booked',
    Loaded: 'loaded',
    Sailing: 'sailing',
    Arrived: 'arrived',
    Discharged: 'discharged',
    Untracked: 'untracked',
    Skipped: 'skipped'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.TradeTransactionAction = {
    TransactionDetailsSpecified: 'transaction_details_specified',
    FeeAccepted: 'fee_accepted',
    TransactionAccepted: 'transaction_accepted',
    TransactionChangeRequested: 'transaction_change_requested',
    ComplianceConfirmed: 'compliance_confirmed',
    TransactionAgreed: 'transaction_agreed',
    MilestoneDocumentsUploaded: 'milestone_documents_uploaded',
    MilestoneDocumentsApproved: 'milestone_documents_approved',
    MilestonePaymentPaid: 'milestone_payment_paid',
    MilestonePaymentReceived: 'milestone_payment_received',
    AmendmentRequested: 'amendment_requested',
    AmendmentRequestAccepted: 'amendment_request_accepted',
    ShipmentStarted: 'shipment_started',
    LoadedOnShip: 'loaded_on_ship',
    TrackingSkipped: 'tracking_skipped',
    ShipmentArrived: 'shipment_arrived',
    ArrivalConfirmed: 'arrival_confirmed',
    ArrivalObjected: 'arrival_objected',
    Resolved: 'resolved',
    FundsReleased: 'funds_released',
    Previous: 'previous',
    Deleted: 'deleted',
    Rejected: 'rejected'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.TradeTransactionDocumentType = {
    BillOfLading: 'bill_of_lading',
    ProofOfDelivery: 'proof_of_delivery',
    CertificateOfOrigin: 'certificate_of_origin',
    PackingList: 'packing_list',
    InspectionReport: 'inspection_report',
    DangerousGoodsDeclaration: 'dangerous_goods_declaration',
    TechnicalSpecs: 'technical_specs',
    SafetyDataSheet: 'safety_data_sheet',
    Cmr: 'cmr',
    CargoPicture: 'cargo_picture',
    Other: 'other'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.TradeTransactionRole = {
    Buyer: 'buyer',
    Seller: 'seller'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.TradeTransactionStateStatus = {
    SellerSpecifyDetails: 'seller_specify_details',
    SellerReviewFee: 'seller_review_fee',
    BuyerReviewTransaction: 'buyer_review_transaction',
    SellerCompliance: 'seller_compliance',
    BuyerCompliance: 'buyer_compliance',
    SellerAgreeTransaction: 'seller_agree_transaction',
    BuyerAgreeTransaction: 'buyer_agree_transaction',
    EscrowAgreeTransaction: 'escrow_agree_transaction',
    MilestoneDocumentsUpload: 'milestone_documents_upload',
    MilestoneDocumentsReview: 'milestone_documents_review',
    MilestonePayment: 'milestone_payment',
    MilestonePaymentNotification: 'milestone_payment_notification',
    SellerReviewAmendmentRequest: 'seller_review_amendment_request',
    BuyerReviewAmendmentRequest: 'buyer_review_amendment_request',
    EscrowReviewAmendmentRequest: 'escrow_review_amendment_request',
    StartShipment: 'start_shipment',
    LoadOnShip: 'load_on_ship',
    RetrieveTracking: 'retrieve_tracking',
    TrackingNotFound: 'tracking_not_found',
    InTransit: 'in_transit',
    ArriveShipment: 'arrive_shipment',
    ConfirmArrival: 'confirm_arrival',
    OnHold: 'on_hold',
    ReleaseFunds: 'release_funds',
    Completed: 'completed',
    Deleted: 'deleted',
    Rejected: 'rejected'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.TradeTransactionStatus = {
    Details: 'details',
    Compliance: 'compliance',
    Agreement: 'agreement',
    Payment: 'payment',
    Departure: 'departure',
    InTransit: 'in_transit',
    Release: 'release',
    Completed: 'completed',
    Deleted: 'deleted',
    Rejected: 'rejected'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.TransportMilestoneType = {
    Departure: 'at_departure',
    ShipLoading: 'at_ship_loading',
    Arrival: 'at_arrival'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.TransportStatus = {
    Pending: 'pending',
    InTransit: 'in_transit',
    Arrived: 'arrived'
};
/**
 * TradeTransactionsApi - axios parameter creator
 * @export
 */
exports.TradeTransactionsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * calculate trade transaction charges
         * @param {TradeTransactionCalculation} tradeTransactionCalculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateTradeTransactionCharges: function (tradeTransactionCalculation, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tradeTransactionCalculation' is not null or undefined
                            common_1.assertParamExists('calculateTradeTransactionCharges', 'tradeTransactionCalculation', tradeTransactionCalculation);
                            localVarPath = "/transactions/trade/calculation";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(tradeTransactionCalculation, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create trade transaction
         * @param {TradeTransactionCreation} tradeTransactionCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTradeTransaction: function (tradeTransactionCreation, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'tradeTransactionCreation' is not null or undefined
                            common_1.assertParamExists('createTradeTransaction', 'tradeTransactionCreation', tradeTransactionCreation);
                            localVarPath = "/transactions/trade";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(tradeTransactionCreation, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get carriers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarriers: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/carriers";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get trade transaction
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeTransaction: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('getTradeTransaction', 'id', id);
                            localVarPath = "/transactions/trade/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get trade transaction count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeTransactionCount: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/transactions/trade/count";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get trade transactions
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeTransactions: function (page, size, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/transactions/trade";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (page !== undefined) {
                                localVarQueryParameter['page'] = page;
                            }
                            if (size !== undefined) {
                                localVarQueryParameter['size'] = size;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get trade transactions payments statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeTransactionsPaymentsStatistics: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/transactions/trade/payment_statistics";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get trade transactions statistics
         * @param {string} startDate
         * @param {string} endDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeTransactionsStatistics: function (startDate, endDate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'startDate' is not null or undefined
                            common_1.assertParamExists('getTradeTransactionsStatistics', 'startDate', startDate);
                            // verify required parameter 'endDate' is not null or undefined
                            common_1.assertParamExists('getTradeTransactionsStatistics', 'endDate', endDate);
                            localVarPath = "/transactions/trade/statistics";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (startDate !== undefined) {
                                localVarQueryParameter['start_date'] = (startDate instanceof Date) ?
                                    startDate.toISOString() :
                                    startDate;
                            }
                            if (endDate !== undefined) {
                                localVarQueryParameter['end_date'] = (endDate instanceof Date) ?
                                    endDate.toISOString() :
                                    endDate;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Updated trade transaction
         * @param {string} id
         * @param {TradeTransactionPatch} tradeTransactionPatch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTradeTransaction: function (id, tradeTransactionPatch, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            common_1.assertParamExists('updateTradeTransaction', 'id', id);
                            // verify required parameter 'tradeTransactionPatch' is not null or undefined
                            common_1.assertParamExists('updateTradeTransaction', 'tradeTransactionPatch', tradeTransactionPatch);
                            localVarPath = "/transactions/trade/{id}"
                                .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication basicAuth required
                            // http basic authentication required
                            common_1.setBasicAuthToObject(localVarRequestOptions, configuration);
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, common_1.setBearerAuthToObject(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(tradeTransactionPatch, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * TradeTransactionsApi - functional programming interface
 * @export
 */
exports.TradeTransactionsApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.TradeTransactionsApiAxiosParamCreator(configuration);
    return {
        /**
         * calculate trade transaction charges
         * @param {TradeTransactionCalculation} tradeTransactionCalculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateTradeTransactionCharges: function (tradeTransactionCalculation, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.calculateTradeTransactionCharges(tradeTransactionCalculation, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create trade transaction
         * @param {TradeTransactionCreation} tradeTransactionCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTradeTransaction: function (tradeTransactionCreation, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createTradeTransaction(tradeTransactionCreation, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get carriers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarriers: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCarriers(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get trade transaction
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeTransaction: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTradeTransaction(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get trade transaction count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeTransactionCount: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTradeTransactionCount(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get trade transactions
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeTransactions: function (page, size, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTradeTransactions(page, size, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get trade transactions payments statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeTransactionsPaymentsStatistics: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTradeTransactionsPaymentsStatistics(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get trade transactions statistics
         * @param {string} startDate
         * @param {string} endDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeTransactionsStatistics: function (startDate, endDate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTradeTransactionsStatistics(startDate, endDate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Updated trade transaction
         * @param {string} id
         * @param {TradeTransactionPatch} tradeTransactionPatch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTradeTransaction: function (id, tradeTransactionPatch, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateTradeTransaction(id, tradeTransactionPatch, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * TradeTransactionsApi - factory interface
 * @export
 */
exports.TradeTransactionsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.TradeTransactionsApiFp(configuration);
    return {
        /**
         * calculate trade transaction charges
         * @param {TradeTransactionCalculation} tradeTransactionCalculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateTradeTransactionCharges: function (tradeTransactionCalculation, options) {
            return localVarFp.calculateTradeTransactionCharges(tradeTransactionCalculation, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create trade transaction
         * @param {TradeTransactionCreation} tradeTransactionCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTradeTransaction: function (tradeTransactionCreation, options) {
            return localVarFp.createTradeTransaction(tradeTransactionCreation, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get carriers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarriers: function (options) {
            return localVarFp.getCarriers(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get trade transaction
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeTransaction: function (id, options) {
            return localVarFp.getTradeTransaction(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get trade transaction count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeTransactionCount: function (options) {
            return localVarFp.getTradeTransactionCount(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get trade transactions
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeTransactions: function (page, size, options) {
            return localVarFp.getTradeTransactions(page, size, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get trade transactions payments statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeTransactionsPaymentsStatistics: function (options) {
            return localVarFp.getTradeTransactionsPaymentsStatistics(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get trade transactions statistics
         * @param {string} startDate
         * @param {string} endDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeTransactionsStatistics: function (startDate, endDate, options) {
            return localVarFp.getTradeTransactionsStatistics(startDate, endDate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Updated trade transaction
         * @param {string} id
         * @param {TradeTransactionPatch} tradeTransactionPatch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTradeTransaction: function (id, tradeTransactionPatch, options) {
            return localVarFp.updateTradeTransaction(id, tradeTransactionPatch, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * TradeTransactionsApi - object-oriented interface
 * @export
 * @class TradeTransactionsApi
 * @extends {BaseAPI}
 */
var TradeTransactionsApi = /** @class */ (function (_super) {
    __extends(TradeTransactionsApi, _super);
    function TradeTransactionsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * calculate trade transaction charges
     * @param {TradeTransactionCalculation} tradeTransactionCalculation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeTransactionsApi
     */
    TradeTransactionsApi.prototype.calculateTradeTransactionCharges = function (tradeTransactionCalculation, options) {
        var _this = this;
        return exports.TradeTransactionsApiFp(this.configuration).calculateTradeTransactionCharges(tradeTransactionCalculation, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create trade transaction
     * @param {TradeTransactionCreation} tradeTransactionCreation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeTransactionsApi
     */
    TradeTransactionsApi.prototype.createTradeTransaction = function (tradeTransactionCreation, options) {
        var _this = this;
        return exports.TradeTransactionsApiFp(this.configuration).createTradeTransaction(tradeTransactionCreation, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get carriers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeTransactionsApi
     */
    TradeTransactionsApi.prototype.getCarriers = function (options) {
        var _this = this;
        return exports.TradeTransactionsApiFp(this.configuration).getCarriers(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get trade transaction
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeTransactionsApi
     */
    TradeTransactionsApi.prototype.getTradeTransaction = function (id, options) {
        var _this = this;
        return exports.TradeTransactionsApiFp(this.configuration).getTradeTransaction(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get trade transaction count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeTransactionsApi
     */
    TradeTransactionsApi.prototype.getTradeTransactionCount = function (options) {
        var _this = this;
        return exports.TradeTransactionsApiFp(this.configuration).getTradeTransactionCount(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get trade transactions
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeTransactionsApi
     */
    TradeTransactionsApi.prototype.getTradeTransactions = function (page, size, options) {
        var _this = this;
        return exports.TradeTransactionsApiFp(this.configuration).getTradeTransactions(page, size, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get trade transactions payments statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeTransactionsApi
     */
    TradeTransactionsApi.prototype.getTradeTransactionsPaymentsStatistics = function (options) {
        var _this = this;
        return exports.TradeTransactionsApiFp(this.configuration).getTradeTransactionsPaymentsStatistics(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get trade transactions statistics
     * @param {string} startDate
     * @param {string} endDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeTransactionsApi
     */
    TradeTransactionsApi.prototype.getTradeTransactionsStatistics = function (startDate, endDate, options) {
        var _this = this;
        return exports.TradeTransactionsApiFp(this.configuration).getTradeTransactionsStatistics(startDate, endDate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Updated trade transaction
     * @param {string} id
     * @param {TradeTransactionPatch} tradeTransactionPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeTransactionsApi
     */
    TradeTransactionsApi.prototype.updateTradeTransaction = function (id, tradeTransactionPatch, options) {
        var _this = this;
        return exports.TradeTransactionsApiFp(this.configuration).updateTradeTransaction(id, tradeTransactionPatch, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TradeTransactionsApi;
}(base_1.BaseAPI));
exports.TradeTransactionsApi = TradeTransactionsApi;
