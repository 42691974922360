import React from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import confirm from '../../../../shared/components/confirm'
import MultilineTextField from '../../../../shared/components/multiline_textfield';
import DocumentUpload from '../../../../shared/components/document_upload';
import MultiDocumentUpload from '../../../../shared/components/multi_document_upload';
import Mapper from '../../../../shared/util/mapper'

class CompanyForm extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      registered_name: this.props.compliance.registered_name,
      registration_number: this.props.compliance.registration_number,
      registration_number_document: this.props.compliance.registration_number_document ? this.props.compliance.registration_number_document : {},
      country_of_incorporation: this.getCountry(this.props.compliance.country_of_incorporation_code),
      registered_address: this.props.compliance.registered_address,
      legal_form: this.props.compliance.legal_form,
      venue_of_listing: this.props.compliance.venue_of_listing,
      legal_form_specification: this.props.compliance.legal_form_specification,
      phone_number: this.props.compliance.phone_number,
      email_address: this.props.compliance.email_address,
      bank_account_number: this.props.compliance.bank_account_number,
      bank_name: this.props.compliance.bank_name,
      bank_swift_code: this.props.compliance.bank_swift_code,
      bank_address: this.props.compliance.bank_address,
      beneficiary_name: this.props.compliance.beneficiary_name,
      beneficiary_address: this.props.compliance.beneficiary_address,
      intermediary_bank: this.props.compliance.intermediary_bank,
      intermediary_bank_swift_code: this.props.compliance.intermediary_bank_swift_code,      
      business_activities: this.props.compliance.business_activities,
      singapore_taxed: this.props.compliance.singapore_taxed,
      source_of_wealth: this.props.compliance.source_of_wealth,
      source_of_funds: this.props.compliance.source_of_funds,
      source_of_funds_documents: this.props.compliance.source_of_funds_documents ? this.props.compliance.source_of_funds_documents : [],
      physical_presence: this.props.compliance.physical_presence,
    };

    var that = this;
    Object.keys(this.state).map(function (key) {
      that.props.propertyUpdate(key, that.state[key], true);
    });
  }

  getCountry(code) {
    var country = this.props.countries.find(c => c.code === code);
    if(country) {
      return country.name;
    }
  }
  
  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.setState({ [e.target.name]: value })
    this.props.propertyUpdate(e.target.name, value);

    if(e.target.name === "legal_form") {
      if(value !== "public") {
        this.setState({ venue_of_listing: undefined });
        this.props.propertyUpdate("venue_of_listing", undefined);
      }
      if(value !== "other") {
        this.setState({ legal_form_specification: undefined });
        this.props.propertyUpdate("legal_form_specification", undefined);
      }
    }
  }

  addDocumentToList() {
    this.state.source_of_funds_documents.push({ key: this.getUniqueKey() });
    this.setState({ source_of_funds_documents: this.state.source_of_funds_documents });
    this.props.propertyUpdate("source_of_funds_documents", this.state.source_of_funds_documents);
  }

  removeDocumentFromList(index) {
    confirm("Please confirm", "Are you sure you want to remove this document?").then(() => {
      this.state.source_of_funds_documents.splice(index, 1);
      this.setState({ source_of_funds_documents: this.state.source_of_funds_documents });
      this.props.propertyUpdate("source_of_funds_documents", this.state.source_of_funds_documents);
    });
  }

  documentUploaded(id, file_name, parent_data) {
    if(!this.isEmpty(parent_data.index)) {
      this.state[parent_data.property][parent_data.index].id = id;
      this.state[parent_data.property][parent_data.index].file_name = file_name;
    } else {
      this.state[parent_data.property].id = id;
      this.state[parent_data.property].file_name = file_name;
    }
    this.setState({ [parent_data.property]: this.state[parent_data.property] });
    this.props.propertyUpdate(parent_data.property, this.state[parent_data.property]);
  }

  documentCleared(parent_data) {
    if(parent_data.index) {
      this.state[parent_data.property][parent_data.index].id = undefined;
      this.state[parent_data.property][parent_data.index].file_name = undefined;
    } else {
      this.state[parent_data.property].id = undefined;
      this.state[parent_data.property].file_name = undefined;
    }
    this.setState({ [parent_data.property]: this.state[parent_data.property] });
    this.props.propertyUpdate(parent_data.property, this.state[parent_data.property]);
  }
  
  documentsUpdated(documents, parent_data) {
    this.setState({ [parent_data.property]: documents });
    this.props.propertyUpdate(parent_data.property, documents);
  }


  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  isEmpty(value) {
    return value === undefined || value === null;
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <TextField
              label="Registered name"
              variant="outlined"
              required
              fullWidth
              disabled
              margin="dense"
              value={this.state.registered_name}
              name="registered_name"
              InputLabelProps={{
                shrink: this.state.registered_name !== undefined,
              }}
            />
          </div>
          <div className="col-md-4">
            <TextField
              label="Country of incorporation"
              variant="outlined"
              required
              fullWidth
              disabled
              margin="dense"
              value={this.state.country_of_incorporation}
              name="country_of_incorporation"
              InputLabelProps={{
                shrink: this.state.country_of_incorporation !== undefined,
              }}
            />
          </div>
          <div className="col-md-4">
            <TextField
              label="Registered address"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.state.registered_address}
              name="registered_address"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.registered_address !== undefined,
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <span>Please provide supporting documents for the company registration number (e.g. certificate of registration/incorporation/incumbency or equivalent)</span>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-4">
            <TextField
              label="Company registration number"
              variant="outlined"
              required
              fullWidth
              disabled
              margin="dense"
              value={this.state.registration_number}
              name="registration_number"
              InputLabelProps={{
                shrink: this.state.registration_number !== undefined,
              }}
            />
          </div>
          <div className="col-md-4">
            <DocumentUpload
              title="Registration number document"
              access="escrow_only"
              allowed_account_ids={[]}
              allow_authorised_accounts={false}
              documentUploaded={this.documentUploaded.bind(this)}
              documentCleared={this.documentCleared.bind(this)}
              document_id={this.state.registration_number_document.id}
              file_name={this.state.registration_number_document.file_name}
              parent_data={{ property: "registration_number_document" }} />
          </div>
          <div className="col-md-4">
            <FormControl
              variant="outlined"
              margin="dense"
              fullWidth>
              <InputLabel shrink={!this.isEmpty(this.state.legal_form) } >Legal form *</InputLabel>
              <Select
                native
                required
                label="Legal form *"
                value={this.state.legal_form}
                name="legal_form"
                onChange={this.handleChange.bind(this)}>
                  <option key="legal_form_empty" value=""></option>
                  {Object.keys(Mapper.legalForms()).map(legalForm => {
                    return (<option key={legalForm} value={legalForm}>{Mapper.fromLegalForm(legalForm)}</option>);
                  })}
              </Select>
            </FormControl>
          </div>
        </div>

        { this.state.legal_form === "public" &&
        <div className="row">
          <div className="col-md-12">
            <span>Please specify the listing venue, if any</span>
          </div>
        </div>
        }

        { this.state.legal_form === "public" &&
        <div className="row">
          <div className="col-md-12">
            <TextField
              label="Venue of listing"
              variant="outlined"
              fullWidth
              margin="dense"
              value={this.state.venue_of_listing}
              name="venue_of_listing"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.venue_of_listing !== undefined,
              }}
            />
          </div>
        </div>
        }

        { this.state.legal_form === "other" &&
        <div className="row">
          <div className="col-md-12">
            <span>Please specify the legal form</span>
          </div>
        </div>
        }

        { this.state.legal_form === "other" &&
          <div className="row">
                <div className="col-md-12">
                  <TextField
                    label="Specify legacy form"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    value={this.state.legal_form_specification}
                    name="legal_form_specification"
                    onChange={this.handleChange.bind(this)}
                    InputLabelProps={{
                      shrink: this.state.legal_form_specification !== undefined,
                    }}
                  />
                </div>
          </div>
        }

        <div className="row">
          <div className="col-md-4">
            <TextField
              label="Company phone number"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.state.phone_number}
              name="phone_number"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.phone_number !== undefined,
              }}
            />
          </div>
          <div className="col-md-4">
            <TextField
              label="Company email address"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.state.email_address}
              name="email_address"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.email_address !== undefined,
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <span>Does the company have physical presence in its country of incorporation?</span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <FormControl
              variant="outlined"
              margin="dense"
              fullWidth>
              <InputLabel shrink={!this.isEmpty(this.state.physical_presence) } >Physical pres. *</InputLabel>
              <Select
                native
                required
                label="Physical pres. *"
                value={this.state.physical_presence}
                name="physical_presence"
                onChange={this.handleChange.bind(this)} >
                  <option key="physical_presence_empty" value=""></option>
                  {Object.keys(Mapper.booleans()).map(boolean => {
                    return (<option key={"physical_presence_"+boolean} value={boolean}>{Mapper.fromBoolean(boolean)}</option>);
                  })}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <TextField
              label="Bank account number"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.state.bank_account_number}
              name="bank_account_number"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.bank_account_number !== undefined,
              }}
            />
          </div>
          <div className="col-md-4">
            <TextField
              label="Bank name"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.state.bank_name}
              name="bank_name"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.bank_name !== undefined,
              }}
            />
          </div>
          <div className="col-md-4">
            <TextField
              label="Bank SWIFT"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.state.bank_swift_code}
              name="bank_swift_code"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.bank_swift_code !== undefined,
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <TextField
              label="Bank address"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.state.bank_address}
              name="bank_address"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.bank_address !== undefined,
              }}
            />
          </div>
          <div className="col-md-4">
            <TextField
              label="Beneficiary name​"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.state.beneficiary_name}
              name="beneficiary_name"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.beneficiary_name !== undefined,
              }}
            />
          </div>
          <div className="col-md-4">
            <TextField
              label="Beneficiary address"
              variant="outlined"
              required
              fullWidth
              margin="dense"
              value={this.state.beneficiary_address}
              name="beneficiary_address"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.beneficiary_address !== undefined,
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <TextField
              label="Intermediary bank"
              variant="outlined"
              fullWidth
              margin="dense"
              value={this.state.intermediary_bank}
              name="intermediary_bank"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.intermediary_bank !== undefined,
              }}
            />
          </div>
          <div className="col-md-4">
            <TextField
              label="Intermediary bank SWIFT​"
              variant="outlined"
              fullWidth
              margin="dense"
              value={this.state.intermediary_bank_swift_code}
              name="intermediary_bank_swift_code"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.intermediary_bank_swift_code !== undefined,
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <MultilineTextField
              label="Key business activities, products and/or services"
              variant="outlined"
              required
              fullWidth
              min_rows={4}
              margin="dense"
              value={this.state.business_activities}
              name="business_activities"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.business_activities !== undefined,
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <span>Does the company pay taxes in Singapore?</span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <FormControl
              variant="outlined"
              margin="dense"
              fullWidth>
              <InputLabel shrink={!this.isEmpty(this.state.singapore_taxed) } >Dutch Taxed *</InputLabel>
              <Select
                native
                required
                label="Dutch Taxed *"
                value={this.state.singapore_taxed}
                name="singapore_taxed"
                onChange={this.handleChange.bind(this)} >
                  <option key="singapore_taxed_empty" value=""></option>
                  {Object.keys(Mapper.booleans()).map(boolean => {
                    return (<option key={"singapore_taxed_"+boolean} value={boolean}>{Mapper.fromBoolean(boolean)}</option>);
                  })}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <span>Please provide the source of wealth (e.g. profits from business activities, equities from shareholder(s), investments, sale of asset(s))</span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <MultilineTextField
              label="Source of wealth"
              variant="outlined"
              required
              fullWidth
              min_rows={4}
              margin="dense"
              value={this.state.source_of_wealth}
              name="source_of_wealth"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.source_of_wealth !== undefined,
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <span>Please provide the source of funds (e.g. profits from business activities, bank loan, investments, sale of asset(s))</span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <MultilineTextField
              label="Source of funds"
              variant="outlined"
              required
              fullWidth
              min_rows={4}
              margin="dense"
              value={this.state.source_of_funds}
              name="source_of_funds"
              onChange={this.handleChange.bind(this)}
              InputLabelProps={{
                shrink: this.state.source_of_funds !== undefined,
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <span>Please provide supporting documents for the source of funds (e.g. latest audited financial statements, annual report)</span>
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            <MultiDocumentUpload
              title="Documents"
              required={true}
              access="escrow_only" 
              allowed_account_ids={[]}
              allow_authorised_accounts={false}
              documentsUpdated={this.documentsUpdated.bind(this)}
              documents={this.state.source_of_funds_documents}
              parent_data={{ property: "source_of_funds_documents" }} />
          </div>
        </div>

      </div>
    );
  }
}

export default CompanyForm;
