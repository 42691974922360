import React from 'react';
import { Prompt } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Mapper from '../../../../shared/util/mapper'
import confirm from '../../../../shared/components/confirm'
import ErrorHandler from '../../../../shared/util/error_handler';
import { getAccountCompliance, updateAccountCompliance, createComplianceDocument } from '../../../../shared/services/compliances_service';
import { getCurrentAccount } from '../../../../shared/services/accounts_service';
import { getCountries } from '../../../../shared/services/countries_service';
import AsyncButton from '../../../../shared/components/async_button';
import DomUtil from '../../../../shared/util/dom_util';
import AdditionalForm from './additional_form'
import SanctionsForm from './sanctions_form'
import DirectorsForm from './directors_form'
import AuthorisedRepresentativesForm from './authorised_reps_form'
import ShareholdersForm from './shareholders_form'
import CompanyForm from './company_form'

class ComplianceForm extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      compliance_loaded: false,
      account_loaded: false,
      countries_loaded: false,
      compliance: {},
      account: {},
      countries: [],
      edited: false
    };
  }

  componentDidMount() {
    this.getAccountCompliance();
    this.getCurrentAccount();
    this.getCountries();
    window.scroll({top: 0, left: 0, behavior: 'smooth' });
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload);
  }

  beforeunload(e) {
    e.preventDefault();
    e.returnValue = true;
  }
  
  getAccountCompliance() {
    getAccountCompliance().then((compliance) => {
      if(compliance) {
        this.setState({ compliance: compliance, compliance_loaded: true });
      } else {
        this.setState({ compliance_loaded: true });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getCurrentAccount() {
    getCurrentAccount().then((account) => {
      if (account) {
        this.setState({ account: account, account_loaded: true });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getCountries() {
    getCountries().then((countries) => {
      if (countries) {
        this.setState({ countries: countries, countries_loaded: true });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  propertyUpdate(key, value, initial) {
    this.state.compliance[key] = value;
    this.setState({ compliance: this.state.compliance })

    if(!this.state.edited && !initial) {
      this.state.edited = true;
      this.setState({ edited: true });
      window.addEventListener('beforeunload', this.beforeunload);
    }
  }
  
  updateCompliance(e) {
    e.preventDefault();
    var compliance = Mapper.toCompliance(this.state.compliance);
    updateAccountCompliance(compliance).then(() => {
      this.setState({ edited: false });
      window.removeEventListener('beforeunload', this.beforeunload);
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  createComplianceDocument(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure you want to submit this KYC form?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      var compliance = Mapper.toCompliance(this.state.compliance);
      createComplianceDocument(compliance).then((compliance_document) => {
        DomUtil.enableFormSubmitButtonClass(form);
        this.props.complianceDocumentCreated(compliance_document)
      }).catch(error => {
        DomUtil.enableFormSubmitButtonClass(form);
        ErrorHandler.showError(error);
      });
    });
  }

  isExpired() {
    return new Date(this.props.compliance_document.expires_at) < Date.now();
  }

  getAlert(severity, message) {
    return (
      <div className="row action-required-row">
      <div className="col-md-12">
        <Alert style={{ whiteSpace: "pre-line" }} severity={severity}>{message}</Alert>
      </div>
    </div>
    );
  }

  render() {
    if(!this.state.compliance_loaded || !this.state.account_loaded || !this.state.countries_loaded) {
      return (
        <div>
          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>KYC document</label>
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-md">
                <span>Loading KYC..</span>
              </div>
            </div>
          </div>
        </div>
        );
    }
    
    return (
      <div>

        <Prompt when={this.state.edited} message="Are you sure you want to leave this page, form is not saved." />

        { !this.props.compliance_document &&
          this.getAlert("info", "This form only needs to be filled in once. Subsequently, you will only need to review the form and provide updates if there are any changes.")
        }
        { (this.props.compliance_document && this.props.compliance_document.status === "approved" && this.isExpired()) &&
          this.getAlert("warning", "The KYC has expired, please review the form and provide updates if there are any changes.")
        }
        { (this.props.compliance_document && this.props.compliance_document.status === "clarification") &&
          this.getAlert("warning", "The Escrow Agent asks for clarification, message: " + this.props.compliance_document.clarification)
        }
        { (this.props.compliance_document && this.props.compliance_document.status === "rejected") &&
          this.getAlert("error", "The KYC was declined by the Escrow Agent")
        }

        <div className="container-body">
          
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Note</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <span>As part of Vistra’s customer due diligence, we require the information requested in this questionnaire to understand the profile and activities of our customers. Please complete this questionnaire from the contracting party’s perspective.</span>
            </div>
          </div>

        </div>

        <form autoComplete="off" onSubmit={this.createComplianceDocument.bind(this)}>

          <div className="container-body">    

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Company information</label>
                </div>
              </div>
            </div>
            
            <CompanyForm compliance={this.state.compliance} propertyUpdate={this.propertyUpdate.bind(this)} countries={this.state.countries} />

          </div>

          <div className="container-body">  

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Shareholder information</label>
                </div>
              </div>
            </div>
            
            <ShareholdersForm compliance={this.state.compliance} propertyUpdate={this.propertyUpdate.bind(this)} countries={this.state.countries} />

          </div>

          <div className="container-body">  

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Directors and key executives</label>
                </div>
              </div>
            </div>
            
            <DirectorsForm compliance={this.state.compliance} propertyUpdate={this.propertyUpdate.bind(this)} users={this.state.account.users} countries={this.state.countries} />

          </div>

          <div className="container-body">  

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Authorised representatives</label>
                </div>
              </div>
            </div>
            
            <AuthorisedRepresentativesForm compliance={this.state.compliance} propertyUpdate={this.propertyUpdate.bind(this)} users={this.state.account.users} countries={this.state.countries} />

          </div>

          <div className="container-body">  

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Sanctions</label>
                </div>
              </div>
            </div>

            <SanctionsForm compliance={this.state.compliance} propertyUpdate={this.propertyUpdate.bind(this)} />
            
          </div>

          <div className="container-body">  

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Additional</label>
                </div>
              </div>
            </div>

            <AdditionalForm compliance={this.state.compliance} propertyUpdate={this.propertyUpdate.bind(this)} />

          </div>

          <div className="container-body">  
          
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Create KYC document</label>
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-12">
                <span>The form can be created once it has been filled in completly.</span>
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <AsyncButton
                  type="submit"
                  variant="outlined"
                  color="primary"
                  icon="check"
                  text="Create" />
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={!this.state.edited}
                  onClick={this.updateCompliance.bind(this)}
                  startIcon={<Icon>save</Icon>}>
                  Save
                </Button>
              </div>
            </div>


          
          </div>
        </form>
      </div>
    );
  }
}

export default ComplianceForm;
